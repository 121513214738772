import Image from 'next/image';
import Head from 'next/head';
import { Box, Button, Typography } from '@mui/material';
import { Formik, Form } from 'formik';
import Loading from '../components/general/loading';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import {
  getKitchensApi,
  getUserApi,
  tokenApi,
} from '../services/utilities/apiConfig';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { userSlice } from '../services/redux-toolkit/slices/userSlice';
import { useDispatch } from 'react-redux';
import useFetch from '../services/utilities/useFetch';
import {
  kitchenSlice,
  permissions,
  isKitchIn,
} from '../services/redux-toolkit/slices/kitchenSlice';
import FormikControl from '../components/general/formik/formikControl';
import * as Yup from 'yup';
export default function Login() {
  const { t } = useTranslation('common', 'titleTabs');
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const router = useRouter();
  const { actions } = userSlice;
  const { saveUser } = actions;
  const [getFetch, postFetch] = useFetch();

  useEffect(() => {
    const token = Cookies.get('refreshToken');
    if (token) {
      router.push('/firms');
    }
  }, []);

  const getUserInfo = async () => {
    const currentRoute = router.asPath === '/login' ? '/' : `${router.asPath}`;
    const response = await getFetch(getUserApi);
    const dataInfo = response?.data;
    dispatch(saveUser(dataInfo));
    await getKitchens();
    if (
      dataInfo?.profile?.is_early_adopter_brand ||
      dataInfo?.profile?.is_early_adopter_kitchen
    ) {
      router.push('/partners');
    } else {
      router.push(currentRoute, undefined, {
        locale: dataInfo.profile.language,
      });
    }
  };
  async function getKitchens() {
    const response = await getFetch(getKitchensApi);
    const data = response?.data;
    const firstKitchen = data[0]?.kitchen?.id;
    dispatch(kitchenSlice?.actions?.selectedKitchen(firstKitchen));
    localStorage.setItem('currentKitchen', firstKitchen);
    dispatch(kitchenSlice?.actions?.saveKitchen(data));
    dispatch(permissions());
    dispatch(isKitchIn());
  }

  const handleSubmit = async (values) => {
    setLoading(true);
    const response = await postFetch(tokenApi, { ...values })
      .then(async (response) => {
        const { refresh: refreshToken, access: accessToken } = response?.data;
        Cookies.set('accessToken', accessToken, {
          path: '/',
          secure: true,
        });
        Cookies.set('refreshToken', refreshToken, {
          path: '/',
          secure: true,
          expires: 365,
        });
        await getUserInfo();
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const validationSchema = Yup.object({
    email: Yup.string()
      .email(t('common:invalid_email'))
      .required(t('common:required')),
    password: Yup.string().required(t('common:required')),
  });
  return (
    <>
      <Head>
        <title>{t('titleTabs:login')}</title>
      </Head>
      {loading && <Loading />}
      <Typography
        onClick={() => {
          router.push(router.asPath, undefined, {
            locale: router.locale == 'en' ? 'ar' : 'en',
          });
        }}
        sx={{
          position: 'fixed',
          top: '20px',
          right: '50px',
          color: 'brand.secondaryDark',
          cursor: 'pointer',
          '&:hover': {
            color: 'brand.secondaryMain',
          },
        }}
      >
        {router.locale == 'en' ? 'العربية' : 'English'}
      </Typography>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr' },
          height: '100vh',
        }}
      >
        <Box
          sx={{
            position: 'relative',
            display: { xs: 'none', sm: 'block' },
          }}
        >
          <Image
            src='/images/login/main.webp'
            alt='Blurred glowing Kitchefy logo'
            fill
            priority
            sizes='(min-width: 100%), (min-height: 100%)'
            style={{ objectFit: 'cover' }}
          />
        </Box>
        <Box
          sx={{
            alignSelf: 'center',
            paddingLeft: { xs: '1.4rem', sm: '8.125rem' },
            paddingRight: { xs: '1.4rem', sm: null },
          }}
        >
          <Box
            sx={{
              marginBottom: '3.75rem',
              textAlign: 'center',
              maxWidth: { sm: '25rem' },
            }}
          >
            <Image
              src='/images/logo.svg'
              alt='Kitchefy Logo'
              width={253}
              height={60}
              priority
            />
          </Box>
          <Formik
            initialValues={{ email: '', password: '' }}
            validationSchema={validationSchema}
            validateOnBlur={false}
            onSubmit={handleSubmit}
          >
            <Form>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  maxWidth: { xs: '100%', sm: '25rem' },
                  marginBottom: '3.75rem',
                }}
              >
                <FormikControl
                  control='inputText'
                  name='email'
                  type='email'
                  label={t('email')}
                  sx={{
                    marginBottom: '1rem',
                    width: { sm: '25rem' },
                  }}
                />
                <FormikControl
                  control='inputText'
                  name='password'
                  type='password'
                  label={t('authentication.password')}
                  sx={{
                    width: { sm: '25rem' },
                  }}
                />
              </Box>
              <Button variant='main' type='submit' disabled={loading}>
                <Typography sx={{ fontWeight: '900' }}>
                  {t('authentication.login')}
                </Typography>
              </Button>
            </Form>
          </Formik>
        </Box>
      </Box>
    </>
  );
}
Login.getLayout = function PageLayout(page) {
  return <> {page}</>;
};
export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common', 'titleTabs'])),
    },
  };
}
